import { replace_offer_links } from '../../../../pages/static/pages/js/instrumentation';

function replaceOfferLinks() {
    if (typeof geoCountryCode !== 'undefined' && geoCountryCode !== 'US') {
        // Hide e*Trade Buttons
        const offer_links = document.querySelectorAll('a[href*="/money/offers/brokerages/"]')
        offer_links.forEach((element) => {
            if (element.href.includes('etrade')) {
                element.style.display = 'none'
            }
        })

        // Replace Links to e*Trade and Merrill with Out-Of-Country Message Link
        replace_offer_links('brokerages/etrade-hub', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/etrade-mutual-fund', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/etrade-options', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/etrade-ira', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/etrade-beginner', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/etrade-individual', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-mutual-fund', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-other', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-ira', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-beginner', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-individual', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-hub', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-1', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-compare', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-etf', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-ira-roth', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
        replace_offer_links('brokerages/merrill-edge-trading-apps', 'https://www.fool.com/money/non-us-residents-offer-link-redirect-page/')
    }
}

// geoVarsReady Event is sent from geo_loc_vars.js
window.addEventListener('geoVarsReady', replaceOfferLinks)
